import { AboutHero } from '@/AboutPages/AboutHero';
import { Article } from '@/Article';
import { Seo } from '@/layout/Seo';
import { css } from '@emotion/react';
import { StaticImage } from 'gatsby-plugin-image';
import { Fragment } from 'react';

const MeganTwenge: Page = () => {
    return (
        <Fragment>
            <Seo
                title="About Megan Twenge  – Integrate Institute"
                description="Meet Megan Twenge, director of rehabilitation "
            />
            <AboutHero>
                <StaticImage
                    src="../../../assets/images/about-us/megan.jpg"
                    width={504}
                    height={568}
                    alt="megan twenge"
                />
            </AboutHero>
            <Article>
                <h1
                    css={css`
                        margin: 0;
                        span {
                            display: block;
                        }
                    `}
                >
                    Megan Twenge,
                    <span>MOTR/L</span>
                </h1>
                <h3
                    css={css`
                        margin: 0 0 32px;
                    `}
                >
                    Director of Rehabilitation
                </h3>
                <p>
                    Megan is the director of rehabilitation here at Integrate Institute and has a
                    passion for helping people of all ages reach their movement potential! As a
                    former division one basketball player who has experienced multiple injuries, she
                    excels in understanding and teaching the rehabilitation process, aimed to
                    facilitate quick recovery, injury prevention, and proper movement. She stresses
                    the importance of living an active lifestyle, and being in control of your own
                    journey.
                </p>

                <p>
                    Megan graduated with her Master’s degree in Occupational Therapy and played
                    basketball, each at The University of North Dakota. It was the same place where
                    she met her stud baseball player husband, now working alongside him as business
                    partners: Dr. Alex! They enjoy weight lifting, competing in their respective
                    sports, and spending time with friends and family at the lake.
                </p>
            </Article>
        </Fragment>
    );
};

export default MeganTwenge;
